@import "../../styles/mixins";
@import "../../styles/variables";

.modalContainer {
  .modalTitle {
    display: flex;
    justify-content: space-between;

    svg {
      color: $color-white;
    }
  }

  :global(.MuiDialog-paperScrollPaper) {
    border-radius: 3px;
    background: $background-color;
    color: $color-white;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin: 0;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 1200px;

    @include bp(s) {
      min-width: 600px;
      margin: 32px;
    }

    h2 {
      margin: 0;
      font-weight: 700;
      line-height: 1.55556;
      font-family: $globalFont;
      flex: 0 0 auto;
      padding: 24px 24px 0 38px;
      font-size: 1.0625rem;

      @include bp(s) {
        font-size: 1.125rem;
      }
    }

    .modalSubtitle {
      padding-left: 16px;
    }

    :global(.MuiDialogContent-root) {
      padding: 6px 24px 24px;
      flex: 1 1 auto;
      overflow-y: auto;
      border-top: 0;
      border-bottom: 0;

      :global(.MuiDialogContentText-root) {
        margin: 0;
        line-height: 1.5;
        font-size: 0.875rem;
        font-family: $globalFont;
        font-weight: 400;
        color: rgb(99, 115, 129);
      }
    }

    :global(.MuiDialogActions-root) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
      padding: 24px;
    }
  }

  &.isSmall {
    :global(.MuiDialog-paperScrollPaper) {
      max-width: 700px;
    }
  }

  &.isLarge {
    :global(.MuiDialog-paperScrollPaper) {
      width: 100%;
      max-width: 1000px;
    }
  }
}

$globalFont: "Montserrat", sans-serif;
$interFont: "Inter", sans-serif;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-light: #dc253c;
$primary-main: #C11F33;
$primary-dark: #bd1a2e;
$primary-extra-dark: #8a0d1d;
$primary-darker: $primary-extra-dark;
$primary-darkest: $primary-extra-dark;
$primary-light-background: rgba(66, 204, 18, 0.02);

$color-white: #FFFFFF;

$primary-button-background: #D31F35;
$primary-button-background-hover: #bd1a2e;
$primary-button-shadow: rgba(28, 49, 68, 0.12) 0 4px 8px 0;

$color-grey: rgb(126, 136, 143);
$color-grey-light: #232222;
$color-grey-light-line: rgba(218, 222, 227, 0.1);
$color-grey-text: #D9D9D9;
$color-grey-rich-text: rgba(217, 217, 217, 0.2);
$color-grey-rich-text-hover: rgba(217, 217, 217, 0.3);
$color-grey-icon: #131A29;
$color-grey-border: #EDEDED;
$color-orange: #E9471D;
$color-orange-darker: #ee4216;

$label: #ffffff;
$label-focused: rgb(123, 137, 150);
$label-error: #d32f2f;

$input-border: #ffffff;
$input-border-hover:  rgb(123, 137, 150);
$input-border-focused: $label-focused;
$input-border-error: $label-error;
$input-error-text: $label-error;
$input-background: rgba(217, 217, 217, 0.7);

$background-color: linear-gradient(#000000, #2F2F2F);
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #FFFFFF;
$black-color: #000000;

$red-color: #E13119;
$red-color-darker: #e04321;
$red-color-brighter: #EFC5BD;

$warning-color: #F19A3E;
$warning-color-brighter: #F6C48D;
$warning-color-darker: #98540B;

$sidebar-color: #313638;
$navigation-color: #313638;

$color-success: rgb(34, 154, 22);
$color-error: rgb(183, 33, 54);


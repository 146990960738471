@import "../../styles/variables";
@import "../../styles/mixins";

.navigationContainer {
  display: flex;
  align-items: center;
  color: $primary-main;
  padding: 20px 18px;
  right: 0;
  z-index: 8;
  backdrop-filter: blur(3px);
  justify-content: space-between;
  width: 100%;

  @include bp (m) {
    height: 80px;
    left: 290px;
    padding: 0 18px;
    position: fixed;
    width: calc(100% - 290px);
  }

  .iconItems {
    display: flex;
    align-items: center;

    button, svg {
      color: $primary-main;
    }
  }

  .rightButtonContainer {
    display: flex;
    flex-direction: row;

    .balanceInfo {
      font-family: $globalFont;
      color: $primary-light;
      text-align: center;
      font-size: 0.7rem;
      font-weight: 500;

      @include bp(xs) {
        padding-right: 24px;
      }

      .balance {
        color: $primary-light;
        font-weight: 500;
        font-size: 1.2rem;
      }

      .topUpButton {
        font-size: 0.7rem;
        height: 20px;
      }
    }
  }
}

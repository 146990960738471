@import "../../styles/mixins";
@import "../../styles/variables";

.formCard {
  color: $color-white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  position: relative;
  padding-top: 0.5rem;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-self: flex-start;
}

@import "../../styles/variables";
@import "../../styles/mixins";

.currentAccountContainer {
  margin-left: 20px;
  margin-right: 20px;
  background-color: rgba(145, 158, 171, 0.12);
  border-radius: 3px;
  height: 76px;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    text-align: center;
    border-radius: 50%;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    .title {
      font-family: $globalFont;
      font-weight: 700;
      font-size: 14px;
    }

    .subTitle {
      font-family: $globalFont;
      font-weight: 500;
      font-size: 12px;
      color: $color-white;
      margin-bottom: 4px;
    }

    .companyName {
      font-family: $globalFont;
      font-weight: 700;
    }
  }
}

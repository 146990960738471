@import "styles/variables";
@import "styles/mixins";

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background-color;

  #root {
    height: 100%;

    --toastify-color-success: rgb(34, 154, 22);
    --toastify-font-family: $globalFont;
    --toastify-icon-color-success: rgb(34, 154, 22);
    --toastify-color-progress-success: rgb(34, 154, 22);
  }
}

a {
  text-decoration: none;
  color: $primary-main;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

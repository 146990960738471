@import '../../styles/variables';
@import '../../styles/mixins';

.companySelectForm {
  width: 100%;
  display: flex;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include bp(xl) {
      flex-direction: row;
    }

    > div {
      max-width: 700px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include bp(m) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
